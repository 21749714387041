<template>
    <BaseTag
        v-if="dossiers.length > 1"
        class="entry-metadata__dossier"
    >
        Onderdeel van {{ dossiers.length }} dossiers

        <template
            v-if="showDropdown"
            #dropdown
        >
            <BaseTag
                v-for="dossier in dossiers"
                :key="`entry-metadata-dossier-${dossier.slug}`"
                :element="shouldLinkDossiers ? NuxtLink : 'div'"
                :to="`/${dossier.uri}`"
                class="entry-metadata__dossier"
                :title="dossier.title"
            >
                {{ dossier.title }}
            </BaseTag>
        </template>
    </BaseTag>

    <template v-else>
        <BaseTag
            v-for="dossier in dossiers"
            :key="`entry-metadata-dossier-${dossier.slug}`"
            :element="shouldLinkDossiers ? NuxtLink : 'div'"
            :to="`/${dossier.uri}`"
            class="entry-metadata__dossier"
            :title="dossier.title"
        >
            {{ dossier.title }}
        </BaseTag>
    </template>
</template>

<script setup>
const NuxtLink = resolveComponent('NuxtLink');

defineProps({
    dossiers: {
        type: Array,
        required: true,
        default: () => []
    },
    shouldLinkDossiers: {
        type: Boolean,
        default: true
    },
    showDropdown: {
        type: Boolean,
        default: true
    }
});
</script>
