<template>
    <div class="entry-metadata">
        <BaseTag
            v-if="!userHasAccess && !isFenexOnly(entry)"
            data-background="darkblue"
        >
            <BaseIcon icon="lock" />
            <template v-if="isInternationalOnly(entry)">Internationaal</template>
            <template v-else>Voor leden</template>
        </BaseTag>

        <FenexTag
            v-if="entry.isFenex"
            :show-lock="!userHasAccess"
        />
        <slot name="pre" />
        <slot>
            <DossierTags
                v-if="!!dossierTags && dossierTags.length > 0"
                :dossiers="dossierTags"
                :should-link-dossiers="shouldLinkDossiers"
                :show-dropdown="showDossierDropdown"
            />

            <BaseTag
                v-if="entry.contentType && entry.contentType[0]?.title"
                class="entry-metadata__type"
            >
                {{ entry.contentType[0]?.title }}
            </BaseTag>
        </slot>

        <slot name="extra" />

        <time
            v-if="entry.postDate && showPostDate"
            class="entry-metadata__date"
            :datetime="entry.postDate"
        >
            {{ formatDate(entry.postDate) }}
        </time>
    </div>
</template>

<script setup>
import BaseTag from '@/components/BaseTag/BaseTag.vue';
import { formatDate } from '~/utils/date.js';
import { useAuthStore } from '~/store/auth';
import { hasAccess, isInternationalOnly, isFenexOnly } from '~/utils/access';
import { computed } from 'vue';

const props = defineProps({
    entry: {
        type: Object,
        required: true,
        default: () => {}
    },
    showPostDate: {
        type: Boolean,
        default: true
    },
    showDossierDropdown: {
        type: Boolean,
        default: true
    },
    shouldLinkDossiers: {
        type: Boolean,
        default: true
    }
});

const dossierTags = computed(() => {
    return props.entry?.dossiers ?? [];
});

const userHasAccess = computed(() => {
    const user = useAuthStore().user;

    return hasAccess(props.entry, user?.contentPermissions);
});
</script>

<style lang="less" src="./EntryMetadata.less" />
